<template>
  <a-card class="card" :bordered="false">
    <a-form-model :model="form" :label-col="labelCol" :wrapper-col="wrapperCol">
      <a-row>
        <a-col span="6">
          <a-form-model-item label="SAP代码">
            <a-input v-model="form.sap_code" />
          </a-form-model-item>
        </a-col>
      </a-row>
      <a-row>
        <a-button @click="searchInfo" class="ant-btn-query margin-right10">查询</a-button>
        <a-button type="primary" @click="newRecord" class="margin-right10">新增</a-button>
        <a-button type="danger" :disabled="!selectedRowKeys.length" class="margin-right10" @click="delRecord('all')">删除</a-button>
      </a-row>
    </a-form-model>
    <a-table
      rowKey="id"
      size="middle"
      :scroll="{ x: true }"
      :columns="columns"
      :data-source="data"
      :pagination="pagination"
      @change="changeTable"
      :row-selection="{ selectedRowKeys: selectedRowKeys, onChange: onSelectChange }"
    >
      <div slot="serial" slot-scope="text, record, index">
        {{ index + 1 }}
      </div>
      <div slot="action" slot-scope="text, record">
        <a @click="modifyRecord(record)" class="margin-right10">修改</a>
        <a @click="delRecord('row', record)">删除</a>
      </div>
    </a-table>
    <a-modal v-model="visible" :title="modelTitle" @ok="handleSave" @cancel="handleCancel">
      <a-form-model
        ref="ruleForm"
        :model="formModel"
        :rules="rules"
        :label-col="labelModel"
        :wrapper-col="wrapperModel"
      >
        <a-form-model-item label="集团内SAP代码">
          <a-input v-model="formModel.sap_code" placeholder="不区分都填写一样"/>
        </a-form-model-item>
        <a-form-model-item label="集团外SAP代码">
          <a-input v-model="formModel.out_sap_code" placeholder="不区分都填写一样"/>
        </a-form-model-item>
        <a-form-model-item label="SAP科目名称">
          <a-input v-model="formModel.sap_subject" />
        </a-form-model-item>
        <a-form-model-item label="费用类型">
          <a-select v-model="formModel.sap_fee_type">
            <a-select-option v-for="(item, index) in feeTypeOps" :key="index" :value="item.value">
              {{ item.name }}
            </a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item label="业务类型">
          <a-select v-model="formModel.sap_business_type">
            <a-select-option v-for="(item, index) in businessTypeOps" :key="index" :value="item.value">
              {{ item.name }}
            </a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item label="部门">
          <a-select v-model="formModel.department">
            <a-select-option v-for="(item, index) in this.GLOBAL.departmentMaps" :key="index" :value="item.value">
              {{ item.name }}
            </a-select-option>
          </a-select>
        </a-form-model-item>
      </a-form-model>
    </a-modal>
  </a-card>
</template>
<script>
import { getFmsMetaPage, createFmsMetaInfo, updateFmsMetaInfo, deleteFmsMetaInfo } from '@/api/fms'
import { subjectOptions } from '@/api/common'
export default {
  data() {
    return {
      labelCol: { span: 4 },
      labelModel: { span: 8 },
      wrapperModel: { span: 12 },
      wrapperCol: { span: 14 },
      form: {
        sap_code: '',
        sap_fee_type: undefined
      },
      formModel: {
        id: 0,
        sap_code: '',
        out_sap_code: '',
        sap_subject: '',
        sap_fee_type: 1,
        sap_business_type: undefined,
        department: undefined
      },
      rules: {
        sap_code: [{ required: true, message: '请填写sap代码', trigger: 'blur' }],
        out_sap_code: [{ required: true, message: '请填写集团外sap代码', trigger: 'blur' }]
      },
      feeTypeOps: [
        { name: '应收账款', value: 1 },
        { name: '应付账款', value: 2 }
      ],
      businessTypeOps: [],
      customerTypeOps: [],
      columns: [
        {
          title: '#',
          scopedSlots: { customRender: 'serial' }
        },
        {
          title: '集团内SAP代码',
          dataIndex: 'sap_code'
        },
        {
          title: '集团外SAP代码',
          dataIndex: 'out_sap_code'
        },
        {
          title: 'SAP科目名称',
          dataIndex: 'sap_subject'
        },
        {
          title: '费用类型',
          dataIndex: 'sap_fee_type',
          customRender: text => {
            if (text === 1) {
              return '应收账款'
            } else if (text === 2) {
              return '应付账款'
            }
          }
        },
        {
          title: '业务类型',
          dataIndex: 'sap_business_type',
          customRender: text => {
            for (const item of this.businessTypeOps) {
              if (item.value === text) {
                return item.name
              }
            }
            return ''
          }
        },
        {
          title: '部门',
          dataIndex: 'department',
          customRender: text => {
            return this.GLOBAL.departmentMaps[text].name
          }
        },
        {
          title: '操作',
          dataIndex: 'action',
          fixed: 'right',
          scopedSlots: { customRender: 'action' }
        }
      ],
      data: [],
      selectedRowKeys: [],
      visible: false,
      modelTitle: '新增SAP科目信息',
      modelKey: 'a',
      pagination: {
        current: 1,
        pageSize: 10,
        total: 0,
        showTotal: (total, range) => `显示 ${range[0]} ~ ${range[1]} 条记录，共 ${total} 条记录`
      },
      moduleName: 'subject_info',
      subjectOpts: {}
    }
  },
  created() {
    this.searchInfo()
    subjectOptions().then(res => {
      this.subjectOpts = res
      this.businessTypeOps = res.charge
      this.customerTypeOps = res.type
    })
  },
  methods: {
    getInfo(param) {
      getFmsMetaPage(this.moduleName, {
        page: this.pagination.current,
        page_size: this.pagination.pageSize,
        ...param
      }).then(res => {
        this.data = res.list
        this.pagination.total = res.total
      })
    },
    searchInfo() {
      this.pagination.current = 1
      this.pagination.pageSize = 10
      this.getInfo(Object.assign({}, this.form))
    },
    changeTable(pagination) {
      this.pagination = pagination
      this.getInfo(Object.assign({}, this.form))
    },
    newRecord() {
      this.modelTitle = '新增SAP科目信息'
      this.modelKey = 'a'
      this.visible = true
      this.formModel = {
        id: 0,
        sap_code: '',
        out_sap_code: '',
        sap_subject: '',
        sap_fee_type: 1,
        sap_business_type: undefined,
        department: undefined
      }
    },
    delRecord(flag, record) {
      this.$confirm({
        title: '确认提示',
        content: '确定要删除？',
        onOk: () => {
          let _ids = []
          if (flag === 'all') {
            _ids = this.selectedRowKeys
          } else {
            _ids = [record.id]
          }
          deleteFmsMetaInfo(this.moduleName, { ids: _ids }).then(res => {
            this.$notification['success']({
              message: '提示',
              description: '删除成功.'
            })
            this.searchInfo()
          })
        }
      })
    },
    modifyRecord(record) {
      this.modelTitle = '修改SAP科目信息'
      this.modelKey = null
      this.visible = true
      this.formModel = Object.assign({}, record)
    },
    onSelectChange(selectedRowKeys) {
      this.selectedRowKeys = selectedRowKeys
    },
    handleSave() {
      if (this.modelKey === 'a') {
        // 新增
        createFmsMetaInfo(this.moduleName, this.formModel).then(res => {
          if (res) {
            this.$message.success('新增成功!')
            const param = Object.assign({}, this.form)
            param.page = this.pagination.current
            param.pageSize = this.pagination.pageSize
            this.getInfo(param)
          } else {
            this.$message.error('新增失败!')
          }
        })
      } else {
        // 修改
        updateFmsMetaInfo(this.moduleName, this.formModel).then(res => {
          if (res) {
            this.$message.success('修改成功!')
            const param = Object.assign({}, this.form)
            param.page = this.pagination.current
            param.pageSize = this.pagination.pageSize
            this.getInfo(param)
          } else {
            this.$message.error('修改失败!')
          }
        })
      }
      this.visible = false
    },
    handleCancel(e) {
      this.$refs.ruleForm.resetFields()
      this.visible = false
    }
  }
}
</script>
<style scoped>
.ant-table td {
  white-space: nowrap;
}
.margin-right10 {
  margin-right: 10px;
}
.ant-form-item {
  margin-bottom: 4px;
}
</style>
